import React, { useState, useEffect } from 'react';
import { Router } from '@reach/router'

import LierBonCadeau from 'components/lier-bon-cadeau/';
import useSetCenterCookie from 'hooks/use-set-center-cookie';
import { getCenterName } from 'utils/location';

const UtiliserBonCadeau = (props) => {
  const [centerId, setCenterId] = useState('');
  const centerName = getCenterName(props.location);

  useEffect(() => {
    setCenterId(`centre-${centerName}`);
  }, [centerName]);

  useSetCenterCookie(centerId);

  if (!centerId) {
    return null;
  }

  return (
    <Router path="/">
      <LierBonCadeau centerId={centerId} path="/utiliser-un-bon-cadeau" />
      
    </Router>
  );
};

export default UtiliserBonCadeau;
