import React, { useState, useEffect, useCallback } from 'react';

import Layout from 'components/layout';
import SEO from 'components/seo'

import './LierBonCadeau.scss';

import moment from 'moment';

import { LazyLoadImage } from 'react-lazy-load-image-component';

import BalneoBooking from 'components/balneo-booking/BalneoBooking';
import SpaBookingModal from 'components/spa-booking/SpaBookingModal';
import BookingCompletedModal from 'components/booking-completed/BookingCompletedModal';
import SpaBookingFirstStep from 'components/spa-booking/SpaBookingFirstStep';

import CaliceoButton from 'components/buttons/CaliceoButton';
import CaliceoLink from 'components/buttons/CaliceoLink';
import load_gif from '../../images/load.gif';
import userIsLoggedIn from 'utils/checkLoggedIn';
import { navigate, Link } from 'gatsby';
import Cookies from 'universal-cookie';
import { centerCookieIds } from 'utils/constants';
import API from 'api';
import { notification } from 'antd';
import qs from 'qs';
import { useLazyQuery } from '@apollo/react-hooks';
import { getCenterById } from 'utils/getCentersBy';
import RightArrowIcon from 'components/icon-components/RightArrow';

import PRODUCT_DETAILS_QUERY from 'hooks/use-offer-details';

const cookies = new Cookies();

const LierBonCadeau = (props) => {
  const {
    actions, userInfo, centers, centerId, location
  } = props;


  const [codeCadeau, setCodeCadeau] = useState('');
  const [bonCadeauId, setBonCadeauId] = useState(null);
  const [detailVisible, setDetailVisible] = useState(false);
  const [load_1, setLoad_1] = useState(false);
  const [produit, setProduit] = useState([]);


  const [centerObject, setCenterObject] = useState({});
  const [productDetailsConditions, setProductDetailsConditions] = useState([]);
  const [productAquaoOid, setProductAquaoOid] = useState();


  const [showBookingModal, setShowBookingModal] = useState(false);
  const [bookingData, setBookingData] = useState({});
  const [spaBookingData, setSpaBookingData] = useState([]);
  const [showSpaBookingModal, setShowSpaBookingModal] = useState(false);
  const [showBookingCompleted, setShowBookingCompleted] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [showFirstStepBooking, setShowFirstStepBooking] = useState(false);
  const [goToNextStepBooking, setGoToNextStepBooking] = useState(false);
  const [bonCadeauData, setBonCadeauData] = useState(null);
  const [selectedOid, setSelectedOid] = useState(null);
  const [choices, setChoices] = useState(null);
  const [abonid, setAbonid] = useState('a99');
  const [rs, setRs] = useState('a99');
  const [soid, setSoid] = useState('a99');
  const [showLoader, setShowLoader] = useState(false);
  const [showNoBookingModal, setShowNoBookingModal] = useState(false);
  const [selectedConsumable, setSelectedConsumable] = useState(null);

  const openRegularOffer = (offer) => {
    //console.log(offer);
    if (offer.bt === 'B') {
      openBookingModal(offer);
    } else {
      selectOffer(offer);
    }
  };

  const openBookingModal = (_record) => {
    setSelectedOffer(_record);

    //const tommorow = moment().add(1, 'days').format('DD/MM/YYYY');
    const today = moment().format('DD/MM/YYYY');
    const _bookingData = {
      session: cookies.get(centerCookieIds[centerId]?.cookieName),
      bid: 'b',
      cid: centerCookieIds[centerId]?.cid,
      pid: _record.pid,
      tid: _record.pid,
      date: today,
    };
    const qstring = Object.keys(_bookingData).map((key) => `${key}=${_bookingData[key]}`).join('&');

  };

  const selectOffer = (_offer) => {
    setShowLoader(true);

    setSelectedOffer(_offer);
    const tommorow = moment().add(1, 'days').format('DD/MM/YYYY');
    const today = moment().format('DD/MM/YYYY');
    const spaData = {
      session: cookies.get(centerCookieIds[centerId]?.cookieName),
      bid: 's',
      cid: centerCookieIds[centerId]?.cid,
      pid: _offer.pid,
      tid: 1,
      date: tommorow,
      //date:today
    };
    const qstring = Object.keys(spaData).map((key) => `${key}=${spaData[key]}`).join('&');
    return API.get(`/fctfbot?${qstring}`, { centerId }).then((res) => {
      setShowLoader(false);
      //console.log(res.data.responseData);
      if (res.data.status === 1) {
        //console.log(res.data.responseData);
        setSpaBookingData(res.data.responseData.bookingContent);
        setShowSpaBookingModal(true);
      } else {
        if(res.data.status === 0) {
          setShowNoBookingModal(true);
          if (res.data.responseData.errorMessage != "" && res.data.responseData.errorMessage != undefined) {
            notification.error({
              message: res.data.responseData.errorMessage,
            });
          }
        }else {
          setShowNoBookingModal(true);
          if (res.data.status > 1) {
            if (res.data.responseData.errorMessage != "" && res.data.responseData.errorMessage != undefined) {
              notification.error({
                message: res.data.responseData.errorMessage,
              });
            }
          }
        }
        
      }
    });
  };


  useEffect(() => {
    if (centerId) {
      setCenterObject(getCenterById(centers, centerId));
    }
  }, [centerId, centers]);
  




  const [getProductDetails, productDetailsRes] = useLazyQuery(PRODUCT_DETAILS_QUERY);

  useEffect(() => {
    getProductDetails({
        variables: {
          productDetailsConditions,
        },
      });
  }, [productDetailsConditions]);

  const createProductConditions = useCallback(
    () => {
      const productConditions = [
        { field: 'type', value: 'offre' },
        //{ field: 'nid', value: 431 },
        (centerObject?.name ? { field: 'field_domain_access', value: `${centerObject.domainAccess}` } : []),
        (productAquaoOid ? { field: 'field_aquao_oid', value: productAquaoOid } : []),
      ];
      return productConditions.flat();
    },
    [productAquaoOid, centerObject],
  );

  useEffect(() => {
    setProductDetailsConditions(createProductConditions());
  }, [productAquaoOid, centerObject, createProductConditions]);




const handleChangeVariant = (val) => {
    var choixV = bonCadeauData?.consommables[0]?.elements.find(el => {return el.id === val});
    
    if (choixV === undefined || choixV === null) {
      choixV = bonCadeauData?.consommables[1]?.elements.find(el => {return el.id === val});
    }
    var dur = choixV.duree;
    setSelectedConsumable({"entity": {"fieldRefEntite" : {"entity" : {"fieldDureePrestation":dur}}}, "pid":val})
  };

  

  const selectedCenter = centers.find(el => {return el.centerId === centerId});

  useEffect(() => {
    if (userIsLoggedIn(centerId)) {
      actions.getAccountInfo(
        localStorage.getItem(centerCookieIds[centerId].cookieName),
        centerId,
      );
    } else {
      navigate('/login');
    }
  }, [centers, centerId, actions]);
  
  const changeCodeValue = (e) => {
    setCodeCadeau(e.target.value);
  };
  
  const submitCode = () => {

    if(codeCadeau[0] === "B" && codeCadeau[1] === "C") {
      const postValues = {
        code: codeCadeau,
        session: cookies.get(centerCookieIds[centerId].cookieName),
        cid:centerCookieIds[centerId]?.cid
      };
      setLoad_1(true);
      const qstring = Object.keys(postValues).map((key) => `${key}=${postValues[key]}`).join('&');
      API.get(`/ehfucpndbefbvpk?${qstring}`, { centerId: centerId }).then((res) => {
        setLoad_1(false);
        if (res.data.status === 1) {
          setBonCadeauId(res.data.responseData.bonCadeau.id);
          setProduit(res.data.responseData.bonCadeau);
          //console.log(res.data.responseData);
          setProductAquaoOid(res.data.responseData.produitCadeau.id);
          setSelectedOid(res.data.responseData.produitCadeau.id)
          setDetailVisible(true);
          
        }else {
          
          if(res.data.status > 1) {
            if(res.data.responseData.errorMessage != "" && res.data.responseData.errorMessage != undefined) {
              notification.error({
                message: res.data.errorMessage,
              });
            }else {
              notification.error({
                message: "Erreur ! Merci de contacter votre centre Calicéo le plus proche",
              });
            }
          }else {
            if(res.data.responseData.errorMessage != "" && res.data.responseData.errorMessage != undefined) {
              notification.error({
                message: res.data.responseData.errorMessage,
              });
            }else {
              notification.error({
                message: "Erreur ! Merci de contacter votre centre Calicéo le plus proche",
              });
            }
          }
        }
        
        
        

      });
    }else {
      notification.error({
        message: "Malheureusement votre bon cadeau ne peut être utilisé en ligne, merci de contacter votre établissement.",
      });
    }
  };


  
  const lierCodeCompte = () => {
    setShowLoader(true);
    /*
    path : /api/consume-bon-cadeau-offert

    type : 'POST'

    session
    bccode
    cid
    date
    hour
    aid
    pid
    */

    const _data = {
      session: cookies.get(centerCookieIds[centerId]?.cookieName),
      bccode:codeCadeau,
      cid:centerCookieIds[centerId]?.cid,
      date:"02/03/2023",
      hour:"11:42",
      aid:localStorage.getItem("userOid"),
      pid:productAquaoOid,
      pt:'bc',
    };
    const qstring = Object.keys(_data).map((key) => `${key}=${_data[key]}`).join('&');
    API.post(`/consume-bon-cadeau-offert`, qs.stringify(_data), { centerId }).then((res) => {
      setShowLoader(false);
      console.log(res.data);
      if (res.data.status === 1) {
        navigate('/mon-compte');
      }else {
        if(res.data.status > 1) {
          if(res.data.responseData.errorMessage != "" && res.data.responseData.errorMessage != undefined) {
            notification.error({
              message: res.data.errorMessage,
            });
          }
        }else {
          if(res.data.responseData.errorMessage != "" && res.data.responseData.errorMessage != undefined) {
            notification.error({
              message: res.data.responseData.errorMessage,
            });
          }else {
            notification.error({
              message: "Erreur ! Merci de contacter votre centre Calicéo le plus proche",
            });
          }
        }
      }
    });
  };

  const planifierCode = () => {
    setBonCadeauData(produit);
    setShowFirstStepBooking(true);
  };

  

  
  
  return (
    <Layout location={location}>
      <SEO title="Calicéo - Utiliser un bon cadeau" description="Vous êtes l’heureux destinataire d’un bon Calicéo ? Rendez-vous sur cette page pour l’ajouter à votre compte et l’utiliser !" />
      
      <div className="cart-body--promo-section cart-body--code-cadeau-section cont-utiliser-bon-caedau">
        <h1 className='center-presentation--title' style={{textAlign:'center', width:'100%'}}>FÉLICITATIONS !</h1>
        <p className="promo-section-description">
          <strong>
          Vous avez reçu un bon cadeau,
          indiquer le code reçu sur le bon cadeau que vous avez reçu par mail.
          </strong>
        </p>
        <div className="code-section-input--wrapper">
          <input className="promo-section-input" value={codeCadeau} onChange={changeCodeValue} type="text" />
          <CaliceoButton
            title=""
            primaryButton
            onClick={submitCode}
            classNames="confirm-promo--btn"
            showIcon
          />
        </div>
        
        <div className={"loader "+(load_1 ? 'show' : 'hide')}>
          <img src={load_gif} style={{width:50}} />
        </div>
        <div className={"cont_detail_bon_cadeau "+(detailVisible ? 'show' : 'hide')}>
        
        <div className={"detail-cadeau cont_cache anime "+(!load_1 ? 'show' : 'hide')}>
          <div className="offer--image--wrapper">
            
          </div>
          <div className="offer--body">
            <h3 className="offer--title">Votre cadeau</h3>
            <p className="offer--desc" >Centre Calicéo de {selectedCenter?.name}</p>
            
            <div class="offer offer--bc" role="presentation">
              <div className="offer--image--wrapper">
                {productDetailsRes?.data?.nodeQuery?.entities[0]?.fieldOffreZone1?.entity?.fieldZoneImage?.entity?.fieldMediaImage?.url ? (
                  <LazyLoadImage
                    effect="blur"
                    className="offer--image"
                    src={productDetailsRes?.data?.nodeQuery?.entities[0]?.fieldOffreZone1?.entity?.fieldZoneImage?.entity?.fieldMediaImage?.url}
                  />
                ) : (
                  <LazyLoadImage
                    effect="blur"
                    className="offer--image"
                    src={"https://media.caliceo.com/sites/default/files/styles/large/public/2021-01/shutterstock_64036966.jpg"}
                  />
                )}
                
              </div>
              <div class="offer--body">
                <h3 class="offer--title">{produit?.title}</h3>
                <p class="offer--desc">{produit?.description}</p>

                
                <a className={'primary-caliceo--button navigate-to-offer--btn btnbc'} href={`/produit${productDetailsRes?.data?.nodeQuery?.entities[0]?.path?.alias}?aquaoOid=${productAquaoOid}`} target={"_blank"}>
                  En savoir plus
                  <RightArrowIcon fill={"#ffffff"} />
                </a>
                <div class="offer--modifier--wrapper"></div>
                <div class="offer--footer">
                  {/*<CaliceoLink
                    primaryButton
                    classNames={`navigate-to-offer--btn`}
                    title="En savoir plus"
                    url={
                      `/produit?aquaoOid=${produit?.id}`
                    }
                  /> */}
                </div>
              </div>
            </div>

            <div className="cont_btn_lier">
              {(productAquaoOid === 430 || productAquaoOid === 796 || productAquaoOid === 797) ? (
                <p><b>Merci de vous rendre directement en centre pour profiter de votre cadeau</b></p>
              ) : (
                <>
                  {(produit.branch !== "SPA" || produit?.consommables[0]?.type === "ABONNEMENT") ? (
                    <CaliceoButton
                      iconFill="#8bacdb"
                      title="Lier à mon compte"
                      primaryButton={false}
                      onClick={lierCodeCompte}
                      classNames="simple-push-block--link primary-caliceo--button--inverted centre-btn"
                    />
                  ) : (
                    <CaliceoButton
                      iconFill="#8bacdb"
                      title="Planifier"
                      primaryButton={false}
                      onClick={planifierCode}
                      classNames="simple-push-block--link primary-caliceo--button--inverted centre-btn"
                    />
                  )}
                </>
              )}
            
              
            </div>  
          </div>
        </div>  
          {showBookingModal ? (
        <BalneoBooking
          bookingData={bookingData}
          showBookingModal={showBookingModal}
          setShowBookingModal={setShowBookingModal}
          setBookingData={setBookingData}
          centerId={centerId}
          setShowBookingCompleted={setShowBookingCompleted}
          pid={selectedOffer?.pid}
          bonCadeauData={bonCadeauData}
          choices={choices}
          setChoices={setChoices}
          setAbonid={setAbonid}
          abonid={abonid}
          setRs={setRs}
          rs={selectedOffer?.abonnement?.remaining}
          setSoid={setSoid}
          soid={selectedOffer?.abonnement?.id}
          selectedOffer={selectedOffer}
          sessionOverride={""}
          bcoid={bonCadeauId}
        />
      ) : null}
      {showSpaBookingModal ? (
        <SpaBookingModal
          spaBookingData={spaBookingData}
          setShowSpaCalendar={setShowSpaBookingModal}
          showSpaCalendar={showSpaBookingModal}
          offer={bonCadeauData}
          quantity={null}
          variant={productAquaoOid}
          centerId={centerId}
          isAccountPage={false}
          setSpaBookingData={setSpaBookingData}
          selectedConsumable={selectedConsumable}
          setShowBookingCompleted={setShowBookingCompleted}
          bonCadeauData={bonCadeauData}
          choices={choices}
          setChoices={setChoices}
          setAbonid={setAbonid}
          selectedOid={selectedOid}
          abonid={abonid}
          setRs={setRs}
          rs={rs}
          setSoid={setSoid}
          soid={selectedOffer?.abonnement?.id}
          selectedOffer={selectedOffer}
          getFreshAccountInfo={actions.getFreshAccountInfo}
          setUserProducts={actions.setUserProducts}
          sessionOverride={""}
          bcoid={bonCadeauId}
        />

      ) : null}
      {showBookingCompleted ? (
        <BookingCompletedModal
          selectedVariant={selectedOffer?.oid}
          productType={selectedOffer?.bt === 'B' ? 'BALNEO' : 'SPA'}
          setShowBookingCompleted={setShowBookingCompleted}
          showBookingCompleted={showBookingCompleted}
          isAccountPage
          centerId={centerId}
        />
      ) : null}
      {showFirstStepBooking ? (
        <SpaBookingFirstStep
          showFirstStepBooking={showFirstStepBooking}
          setShowFirstStepBooking={setShowFirstStepBooking}
          offer={bonCadeauData}
          setSelectedOid={setSelectedOid}
          selectedOid={selectedOid}
          selectedOffer={selectedOffer}
          productType={selectedOffer?.bt === 'B' ? 'BALNEO' : 'SPA'}
          handleChangeVariant={handleChangeVariant}
          setGoToNextStepBooking={setGoToNextStepBooking}
          bonCadeauData={bonCadeauData}
          setBonCadeauData={setBonCadeauData}
          openRegularOffer={openRegularOffer}
          centerId={centerId}
          choices={choices}
          setChoices={setChoices}
          setAbonid={setAbonid}
          abonid={abonid}
          setRs={setRs}
          rs={selectedOffer?.abonnement?.remaining}
          setSoid={setSoid}
          soid={selectedOffer?.abonnement?.id}
          setShowLoader={setShowLoader}
          isOffert={produit.offert}
        />
      ) : null}
        </div>
      </div>

      <div className={"loaderBC "+(showLoader ? 'show' : 'hide')}>
        <img src={load_gif} style={{width:50}} />
      </div>
    </Layout>
  );
};

export default LierBonCadeau;
